<script>
import VuePictureSwipe from 'vue3-picture-swipe';
export default {
    data() {
        return {
            items: [{
                    src: 'https://static.wixstatic.com/media/e64c81_8a2c0df797f040aeb172c96e196187cd~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_8a2c0df797f040aeb172c96e196187cd~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_8a2c0df797f040aeb172c96e196187cd~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_8a2c0df797f040aeb172c96e196187cd~mv2.jpg',
                    w: 827,
                    h: 621,
                    alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
                },

                {
                    src: 'https://static.wixstatic.com/media/e64c81_0d05fbb2a72d4e7fa6717ebf7c159065~mv2.jpg/v1/fit/w_417,h_314,q_90/e64c81_0d05fbb2a72d4e7fa6717ebf7c159065~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_0d05fbb2a72d4e7fa6717ebf7c159065~mv2.jpg/v1/fit/w_417,h_314,q_90/e64c81_0d05fbb2a72d4e7fa6717ebf7c159065~mv2.jpg',
                    w: 417,
                    h: 314,
                    htmlAfterThumbnail: '<span class="photos-date">29.12.2021</span>' // optional, insert your html after tag <a> if you need it
                },
                {
                    src: 'https://static.wixstatic.com/media/e64c81_92036e8e04d248b09affb60812866ee7~mv2.jpg/v1/fit/w_411,h_309,q_90/e64c81_92036e8e04d248b09affb60812866ee7~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_92036e8e04d248b09affb60812866ee7~mv2.jpg/v1/fit/w_411,h_309,q_90/e64c81_92036e8e04d248b09affb60812866ee7~mv2.jpg',
                    w: 411,
                    h: 309,
                    htmlAfterThumbnail: '<span class="photos-date">29.12.2021</span>' // optional, insert your html after tag <a> if you need it
                },
                {
                    src: 'https://static.wixstatic.com/media/e64c81_d8007974ddd74858b37dd3bd7eedac65~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_d8007974ddd74858b37dd3bd7eedac65~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_d8007974ddd74858b37dd3bd7eedac65~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_d8007974ddd74858b37dd3bd7eedac65~mv2.jpg',
                    w: 827,
                    h: 621,
                    htmlAfterThumbnail: '<span class="photos-date">29.12.2021</span>' // optional, insert your html after tag <a> if you need it
                },
                {
                    src: 'https://static.wixstatic.com/media/e64c81_741a0f5f449b4bc4925152929c80d2b9~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_741a0f5f449b4bc4925152929c80d2b9~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_741a0f5f449b4bc4925152929c80d2b9~mv2.jpg/v1/fit/w_828,h_1200,q_90/e64c81_741a0f5f449b4bc4925152929c80d2b9~mv2.jpg',
                    w: 827,
                    h: 621,
                    htmlAfterThumbnail: '<span class="photos-date">29.12.2021</span>' // optional, insert your html after tag <a> if you need it
                },
                {
                    src: 'https://static.wixstatic.com/media/e64c81_3c8c4fcc2f854091bce78f545d70747e~mv2.jpg/v1/fit/w_828,h_576,q_90/e64c81_3c8c4fcc2f854091bce78f545d70747e~mv2.jpg',
                    thumbnail: 'https://static.wixstatic.com/media/e64c81_3c8c4fcc2f854091bce78f545d70747e~mv2.jpg/v1/fit/w_828,h_576,q_90/e64c81_3c8c4fcc2f854091bce78f545d70747e~mv2.jpg',
                    w: 828,
                    h: 465,
                    htmlAfterThumbnail: '<span class="photos-date">29.12.2021</span>' // optional, insert your html after tag <a> if you need it
                }
            ]
        };
    },
    components: { VuePictureSwipe }
}
</script>

<template>
    <div class="space-hero">
        <div class="space-hero-content">
            <div class="constraint">
                <h1>Geometric Space</h1>
                <div class="subheader">
                    <h2>Space Division of Geometric Energy Corporation</h2>
                </div>
                <a href="#launches">
                    <div class="button">Read More <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
            </div>
        </div>
        <iframe src="/mars.html" style="left:0px;top:0px;width:100vw;height:100vh;position:fixed;" frameborder="0"></iframe>
    </div>
    <div class="space-about " id="space-about">
        <div class="space-about-content">
            <div class="constraint">
    
                <h1>About</h1>
                <div class="subheader">
                    <h2>Geometric Space is dedicated to multi-planetary colonization by facilitating the expansion of the private space industry through the Geometric Space Rideshare Program and commercializing key space technologies for Moon and Mars settlements.
                    </h2>
                </div>
                <a href="mailto:info@geometricenergy.ca">
                    <div class="button">Contact <img src="@/assets/arrow-right-up-354.svg" style="" alt=""></div>
                </a>
            </div>
        </div>
    </div>
    
    <div class="launches fullheight" id="launches">
        <div class="launches-content">
            <div class="constraint">
                <h1>Geometric Space Rideshare Program</h1>
                <div class="subheader">
                    <h2>Geometric Space offers rideshare services aboard SpaceX launch vehicles complete with satellite development, ground station, operation, and mission management services to Low Earth Orbit, the Moon, and Mars.</h2>
                </div>
                <div class="launch-boxes">
                    <a target="_blank" href="https://www.youtube.com/watch?v=lSRXacd8wU8">
                        <div class="launch-box">
                            <p>Successfully launched January 2023 to Low Earth Orbit.</p>
                            <h2>GEOMETRIC-1</h2>
                            <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
                        </div>
                    </a>
    
                    <a target="_blank" href="https://Twitter.com/YourTimeInSpace">
                        <div class="launch-box">
                            <p>Small satellite rideshare launch to Low Earth Orbit.</p>
                            <h2>GEOMETRIC-2</h2>
                            <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
                        </div>
                    </a>
    
                    <a target="_blank" href="https://twitter.com/elonmusk/status/1391523807148527620">
                        <div class="launch-box" style="overflow:hidden;">
                            <img src="@/assets/dogeimg.jpg" style="width:100%;height:auto;top:0px;right:0px;opacity:0.2;" alt="">
    
                            <p>Lunar Orbit CubeSat.</p>
                            <h2>DOGE-1</h2>
                            <img src="@/assets/arrow-right-up-354.svg" style="" alt="">
    
                        </div>
    
                    </a>
    
                </div>
            </div>
        </div>
        <iframe src="/moon.html" style="pointer-events:none;left:0px;top:0px;width:100vw;height:100%;position:absolute;" frameborder="0"></iframe>
    
    </div>
    
    <div class="rideshare-clients fullheight" id="rideshare-clients">
        <div class="rideshare-clients-content">
            <div class="constraint">
                <div class="half">
    
                    <h1>Rideshare Clients</h1>
    
                </div>
                <div class="half">
                    <a target="_blank" href="https://moxy.io/"><img class="client" src="@/assets/logo_moxy.png" alt=""></a>
                    <a target="_blank" style="flex-flow:row;" href="https://www.genmat.xyz/"><img style="margin:auto;" class="client" src="@/assets/genmat.webp" alt=""><img style="margin:auto;margin-left:-1rem;" class="client" src="@/assets/gen.webp" alt=""></a>
                    <a target="_blank" href="https://www.pointblankllc.com/"><img class="client" src="@/assets/pointblank.svg" style="filter:invert(1)" alt=""></a>
                    <a target="_blank" href="https://www.xisp-inc.com/ACS_brochure.pdf"><img class="client" src="@/assets/alpha.webp" alt=""></a>
                    <a target="_blank" href="https://noclip.org/#/">
                        <div style="flex-flow:row;display:flex;padding-right:1rem;border:1px dashed orange;border-radius:1rem;">
                            <div class="orbiter">
                                <div class="planet"></div>
                                <div class="satellite">
                                    <div class="blinker"></div>
                                </div>
                            </div>
                            <p style="text-align:center;font-size:1rem;font-weight:800;margin:auto;margin-left:-0.25rem;">NOCLIP</p>
                        </div>
                    </a>
    
                </div>
    
            </div>
        </div>
    </div>
    
    
    
    <div class="sat-dev fullheight" id="sat-dev">
        <div class="sat-dev-content">
            <div class="constraint">
                <h1>Satellite Development</h1>
    
                <vue-picture-swipe :items="items"></vue-picture-swipe>
    
            </div>
        </div>
    </div>
    
    <div class="partners fullheight">
        <div class="constraint">
            <a target="_blank" href="https://www.maverickspace.com/"><img src="@/assets/maverick.webp" alt=""></a>
            <a target="_blank" href="https://exobotics.space/"> <img src="@/assets/xob.webp" alt=""></a>
            <a target="_blank" href="https://www.xisp-inc.com/"><img src="@/assets/xisp.webp" alt=""></a></div>
    </div>
</template>


<style lang="scss">
@keyframes rotate {
    0% {
        transform: rotate(0)
    }
    to {
        transform: rotate(360deg)
    }
}

.orbiter {
    width: 64px;
    height: 64px;
    background: transparent;
    position: relative;
    z-index: 10;
    transform: scale(0.5);
    margin: auto;
}

.planet {
    width: 42px;
    height: 42px;
    left: 11px;
    top: 11px;
    border-radius: 100%;
    border: 4px solid white;
    position: absolute;
}

.satellite {
    animation: rotate 10s linear infinite;
    position: absolute;
    transform-origin: 32px 32px;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background: white;
}

.blinker {
    animation: blink 1s ease infinite;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
}

.space-hero {
    display: flex;
    flex-flow: wrap;
    position: relative;
    height: 100vh;
    overflow: hidden;
    width: 100vw;
    background: black;
    background-size: cover;
    background-position: center;
}

.space-hero-content {
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    transition: all 5s ease;
    .constraint {
        h1 {
            line-height: 1;
            font-weight: 600;
            margin-bottom: 1rem;
        }
        h2 {}
        .button {}
    }
    &:hover {}
}

.background-vid {
    height: auto;
    overflow: hidden;
    video {
        min-height: 100vh;
        min-width: 100vw;
        position: fixed;
    }
}

.constraint {
    margin: auto;
    text-align: left;
    width: 1080px;
    max-width: 100%;
}

.space-about {
    background-repeat: repeat-x;
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background-size: contain;
    background-image: url(../assets/noiseback.svg);
    left: 0px;
    background-position: bottom;
}

.space-about-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem;
    margin-bottom: -2px;
    h1 {
        font-weight: 600;
        font-size: 3rem;
        margin: auto;
    }
}

.dlt {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background: black;
    left: 0px;
}

.dlt-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem;
    h1 {
        font-weight: 600;
        font-size: 2rem;
        margin: auto;
    }
    .constraint {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        .dlt-block {
            perspective: 600px;
            margin: auto;
            width: 300px;
            height: 300px;
            cursor: pointer;
            &:hover {
                .wall {
                    background: #ffefb1;
                    box-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                    transform: rotatey(135deg);
                }
                .wall2 {
                    transform: rotatey(135deg)translatez(300px);
                    border-color: white;
                }
                .wall3 {
                    transform: rotatey(45deg)translateZ(-300px);
                    border-color: white;
                }
                .table-item {
                    transform: rotatey(45deg);
                    .atom {
                        font-weight: 400;
                        padding: 8px;
                        left: 1rem;
                        top: 0.5rem;
                        font-size: 3rem;
                        position: absolute;
                        transform: translatez(50px)
                    }
                    .weight {
                        font-weight: 400;
                        padding: 8px;
                        right: 1rem;
                        bottom: 1.5rem;
                        position: absolute;
                        transform: translatez(50px)
                    }
                    .symbol {
                        font-weight: 600;
                        font-size: 10rem;
                        position: absolute;
                        width: 100%;
                        line-height: 300px;
                        text-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                        text-align: center;
                        transform: translatez(-50px)
                    }
                }
            }
            .wall {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                border: 2px solid #fff5;
                position: absolute;
                transform: rotatey(90deg);
            }
            .wall2 {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                position: absolute;
                border: 2px solid #fff5;
                transform: rotatey(90deg)translatez(300px);
            }
            .wall3 {
                background: black;
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                border: 2px solid #fff5;
                transform-origin: left;
                position: absolute;
                transform: rotatey(0deg)translateZ(-300px);
            }
            .table-item {
                transition: all 0.5s ease;
                width: 300px;
                height: 300px;
                transform-origin: left;
                position: relative;
                border: 1px solid white;
                transform: rotatey(0deg);
                perspective: 600px;
                &:after {
                    content: '';
                }
                .atom {
                    transition: all 0.5s ease;
                    font-weight: 600;
                    padding: 8px;
                    left: 1.5rem;
                    top: 1rem;
                    font-size: 2rem;
                    position: absolute;
                }
                .weight {
                    transition: all 1s ease;
                    font-weight: 600;
                    padding: 8px;
                    right: 2rem;
                    bottom: 1.5rem;
                    position: absolute;
                }
                .symbol {
                    transition: all 1.5s ease;
                    font-weight: 600;
                    font-size: 8rem;
                    position: absolute;
                    width: 100%;
                    line-height: 300px;
                    text-align: center;
                }
            }
        }
        .half {
            &:nth-child(1) {
                width: auto;
                margin-bottom: 1rem;
            }
            &:nth-child(2) {
                width: 700px;
            }
        }
    }
}

.rideshare-clients {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    background: black;
    left: 0px;
}

.rideshare-clients-content {
    max-width: 100%;
    margin: auto;
    padding: 8rem 4rem 12rem;
    h1 {
        font-weight: 600;
        font-size: 3rem;
        text-align: center;
        margin: auto;
        margin-bottom: 2rem;
        &:after {
            content: '';
            margin: auto;
            position: absolute;
            bottom: -2rem;
            right: calc(50% - 100px);
            width: 200px;
            border-bottom: 1px solid white;
        }
    }
    .constraint {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .rideshare-clients-img {
            transition: all 0.2s ease;
            filter: contrast(1.2)saturate(0);
            width: auto;
            &:hover {
                filter: contrast(1.2)saturate(1);
            }
        }
        .half {
            a {
                display: flex;
                width: max-content;
                flex-flow: column;
                justify-content: center;
                transition: all 0.2s ease;
                &:hover {
                    transform: scale(1.1);
                }
                .client {
                    max-height: 100px;
                    height: auto;
                    padding: 1rem;
                    margin: 1rem;
                }
            }
            &:nth-child(2) {
                width: auto;
                margin-bottom: 1rem;
                display: flex;
                justify-content: center;
                flex-flow: wrap;
                max-width: 100%;
            }
            &:nth-child(1) {
                width: auto;
            }
        }
    }
}

.subheader {
    margin: 2rem 0rem;
    margin-top: 0rem;
}

.launches {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    color: rgb(255, 255, 255) !important;
    background: #000000;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0px;
    padding-bottom: 8rem;
    overflow: hidden;
}

.launches-content {
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    transition: all 5s ease;
    .constraint {
        h1 {
            color: rgb(255, 255, 255);
            line-height: 3rem;
            margin-bottom: 1rem;
            font-weight: 600;
        }
    }
    &:hover {}
}

.launch-boxes {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 2rem;
}

.launch-box {
    width: 360px;
    height: 360px;
    filter: invert(0);
    transition: all 0.2s ease;
    backdrop-filter: brightness(0.5)blur(20px);
    box-shadow: 0px 0px 0px 1px white;
    &:hover {
        filter: invert(1);
        background: black;
        box-shadow: 0px 0px 0px 1px black;
        p {
            opacity: 1;
        }
        img {
            &:nth-child(1) {
                filter: invert(1);
            }
        }
    }
    p {
        transition: all 0.2s ease;
        font-weight: 600;
        line-height: 2rem;
        left: 1rem;
        padding-right: 2rem;
        top: 0.75rem;
        color: white;
    }
    h2 {
        transition: all 0.2s ease;
        font-weight: 400;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        color: white;
        font-size: 2rem;
        line-height: 2rem;
    }
    img {
        transition: all 0.2s ease;
        height: 32px;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
}

.sat-dev {
    width: 100vw;
    min-width: 100vw;
    z-index: 2;
    color: rgb(255, 255, 255) !important;
    background: black;
    left: 0px;
}

.sat-dev-content {
    width: 100vw;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    z-index: 2;
    max-width: 100%;
    margin: auto;
    padding: 4rem;
    transition: all 5s ease;
    .constraint {
        h1 {
            font-weight: 600;
            font-size: 3rem;
            text-align: center;
            margin: auto;
            margin-bottom: 5rem;
            &:after {
                content: '';
                margin: auto;
                position: absolute;
                bottom: -2rem;
                right: calc(50% - 100px);
                width: 200px;
                border-bottom: 1px solid white;
            }
        }
    }
    &:hover {}
}

.sat-dev-boxes {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 2rem;
}

.sat-dev-box {
    width: 360px;
    height: 500px;
    iframe {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

.gallery-thumbnail {
    a {
        overflow: hidden;
        width: 340px;
        height: auto;
        display: flex;
        img {
            height: fit-content;
            min-width: 100%;
        }
    }
}

.my-gallery {
    display: flex;
    width: 1080px;
    max-width: 100%;
    flex-flow: wrap;
}

.pswp__button {
    top: 50%;
}

.pswp__ui {
    height: 100vh;
}

@media (max-width: 1024px) {
    .gallery-thumbnail {
        a {
            overflow: hidden;
            width: calc(100vw - 4rem - 10px);
            height: auto;
            display: flex;
            img {
                height: auto;
                min-width: 100%;
            }
        }
    }
    .my-gallery {
        display: flex;
        width: 1080px;
        max-width: 100%;
        flex-flow: wrap;
    }
    .pswp__button {
        top: 50%;
    }
    .pswp__ui {
        height: 100vh;
    }
    .sat-dev {
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        color: rgb(255, 255, 255) !important;
        background: #000000;
        left: 0px;
    }
    .space-about {
        padding-top: 2rem;
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        background-size: cover;
        background-image: url(../assets/noiseback.svg);
        left: 0px;
        background-position: bottom;
    }
    .sat-dev-content {
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 2rem;
        transition: all 5s ease;
        .constraint {
            h1 {
                color: rgb(255, 255, 255);
                line-height: 2rem;
                font-size: 2rem;
                margin-bottom: 1rem;
            }
        }
        &:hover {}
    }
    .sat-dev-boxes {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 2rem;
    }
    .sat-dev-box {
        margin-bottom: 2rem;
        width: calc(100vw - 4rem);
        height: calc(120vw - 4rem);
        iframe {
            pointer-events: none;
            width: 100%;
            height: 100%;
        }
    }
    .space-hero-content {
        padding: 2rem;
    }
    .launches-content {
        width: 100vw;
        display: flex;
        flex-flow: wrap;
        justify-content: flex-start;
        z-index: 2;
        max-width: 100%;
        margin: auto;
        padding: 4rem;
        transition: all 5s ease;
        .constraint {
            h1 {
                color: rgb(255, 255, 255);
                line-height: 2rem;
                margin-bottom: 1rem;
            }
        }
        &:hover {}
    }
    .subheader {
        margin: 2rem 0rem;
        margin-top: 0rem;
    }
    h1 {
        line-height: 2.2rem;
        font-size: 2rem;
    }
    h2 {
        line-height: 1.5rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .launch-box {
        width: calc(100vw - 4rem);
        height: calc(100vw - 4rem);
        filter: invert(0);
        &:hover {
            filter: invert(1);
        }
        p {
            font-weight: 600;
            line-height: 2rem;
            left: 1rem;
            padding-right: 2rem;
            top: 0.75rem;
            color: white;
        }
        h2 {
            font-weight: 400;
            position: absolute;
            bottom: 0rem;
            left: 1rem;
            color: white;
            font-size: 2rem;
            line-height: 2rem;
        }
        img {
            height: 32px;
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }
    .space-about-content,
    .launches-content,
    .dlt-content {
        padding: 8rem 2rem;
        h1 {
            font-weight: 600;
            font-size: 2rem;
            margin-bottom: 1rem;
        }
    }
    .dlt-content {
        max-width: 100%;
        margin: auto;
        padding: 8rem 2rem;
        h1 {
            font-weight: 600;
            font-size: 2rem;
            margin: auto;
        }
        .constraint {
            display: flex;
            flex-flow: wrap;
            justify-content: space-between;
            .dlt-block {
                perspective: 600px;
                margin: auto;
                width: calc(100vw - 4rem);
                height: calc(100vw - 4rem);
                cursor: pointer;
                &:hover {
                    .wall {
                        background: #ffefb1;
                        box-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                        transform: rotatey(135deg);
                    }
                    .wall2 {
                        transform: rotatey(135deg)translatez(calc(100vw - 4rem));
                        border-color: white;
                    }
                    .wall3 {
                        transform: rotatey(45deg)translateZ(calc(-100vw + 4rem));
                        border-color: white;
                    }
                    .table-item {
                        transform: rotatey(45deg);
                        .atom {
                            font-weight: 400;
                            padding: 8px;
                            left: 1rem;
                            top: 0.5rem;
                            font-size: 3rem;
                            position: absolute;
                            transform: translatez(50px)
                        }
                        .weight {
                            font-weight: 400;
                            padding: 8px;
                            right: 1rem;
                            bottom: 1.5rem;
                            position: absolute;
                            transform: translatez(50px)
                        }
                        .symbol {
                            font-weight: 600;
                            font-size: 10rem;
                            position: absolute;
                            width: 100%;
                            line-height: calc(100vw - 4rem);
                            text-shadow: 0px 0px 10px #ffdd81, 0px 0px 20px #ffdd81, 0px 0px 60px #ffdd81;
                            text-align: center;
                            transform: translatez(-50px)
                        }
                    }
                }
                .wall {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    border: 2px solid #fff5;
                    position: absolute;
                    transform: rotatey(90deg);
                }
                .wall2 {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    position: absolute;
                    border: 2px solid #fff5;
                    transform: rotatey(90deg)translatez(calc(100vw - 4rem));
                }
                .wall3 {
                    background: black;
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    border: 2px solid #fff5;
                    transform-origin: left;
                    position: absolute;
                    transform: rotatey(0deg)translateZ(calc(-100vw + 4rem));
                }
                .table-item {
                    transition: all 0.5s ease;
                    width: calc(100vw - 4rem);
                    height: calc(100vw - 4rem);
                    transform-origin: left;
                    position: relative;
                    border: 1px solid white;
                    transform: rotatey(0deg);
                    perspective: 600px;
                    &:after {
                        content: '';
                    }
                    .atom {
                        transition: all 0.5s ease;
                        font-weight: 600;
                        padding: 8px;
                        left: 1.5rem;
                        top: 1rem;
                        font-size: 2rem;
                        position: absolute;
                    }
                    .weight {
                        transition: all 1s ease;
                        font-weight: 600;
                        padding: 8px;
                        right: 2rem;
                        bottom: 1.5rem;
                        position: absolute;
                    }
                    .symbol {
                        transition: all 1.5s ease;
                        font-weight: 600;
                        font-size: 8rem;
                        position: absolute;
                        width: 100%;
                        line-height: calc(100vw - 4rem);
                        text-align: center;
                    }
                }
            }
            .half {
                &:nth-child(1) {
                    width: auto;
                    margin-bottom: 1rem;
                }
                &:nth-child(2) {
                    width: 700px;
                }
            }
        }
    }
    .rideshare-clients {
        width: 100vw;
        min-width: 100vw;
        z-index: 2;
        background: black;
        left: 0px;
    }
    .rideshare-clients-content {
        max-width: 100%;
        margin: auto;
        padding: 8rem 4rem 12rem;
        h1 {
            font-weight: 600;
            font-size: 2rem;
            margin: auto;
            margin-bottom: 1rem;
        }
        .constraint {
            display: flex;
            flex-flow: wrap;
            justify-content: center;
            .rideshare-clients-img {
                transition: all 0.2s ease;
                filter: contrast(1.2)saturate(0);
                width: calc(100vw - 4rem);
                &:hover {
                    filter: contrast(1.2)saturate(1);
                }
            }
            .half {
                &:nth-child(2) {
                    width: calc(100vw - 4rem);
                    margin-bottom: 1rem;
                    justify-content: center;
                    a {
                        display: flex;
                        width: max-content;
                        flex-flow: column;
                        justify-content: center;
                        transition: all 0.2s ease;
                        margin: 1rem;
                        &:hover {
                            transform: scale(1.1);
                        }
                        .client {
                            max-height: 100px;
                            height: auto;
                        }
                    }
                }
                &:nth-child(1) {
                    width: calc(100vw - 4rem);
                }
            }
        }
    }
    .space-about {
        margin-top: -20vh
    }
    .space-hero {
        background-attachment: fixed;
        height: 120vh;
        .constraint {
            h1 {
                font-size: 2rem;
            }
        }
    }
    .background-vid {
        display: none;
    }
}
</style>
